import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Container, Row, Col } from "react-bootstrap"
import { navigate } from "@reach/router"
import loadable from '@loadable/component'

import InputField from './elements/input'
import SelectField from './elements/select'
import MultiSelectField from './elements/multi-select'
import FileField from './elements/file'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import $ from 'jquery/dist/jquery.min.js'
import { getIpAddress } from "../common/site/functions"
import { getAllCookie } from "../common/site/cookie";
import ChartResult from "../DevelopmentDetails/ChartArea/ChartAreaResult"
import LoadingImage from "../../images/loading.svg"

// import "../../scss/forms.scss";

import axios from "axios"
import * as qs from "query-string"

const PostcodeLookUp = loadable(() => import('./elements/postcode-lookup'))

function GetStartedForm(props) {
    const [ipAddress, setIpAddress] = useState("");
    const [validated, setValidated] = useState(false);
    const [showerror, setShowerror] = useState(false);
    const [showthankyou, setThankyou] = useState(false);
    const [selectedInterst, setSelectIntrest] = useState("");
    const [errormsg, setErrorMsg] = useState("Highlighted fields are required");
    const [isValid, setIsValid] = useState(true);
    const [finalresponse, setFinalResponse] = useState(false);
    const [showmainload, setMainLoad] = useState(false);
    const [buyerresult, setBuyerResult] = useState('');
    const [postcode, setPostcode] = useState('');
    const [showemail, setShowEmail] = useState(true);
    // const [serverResponse, setServerResponse] = React.useState(``)

    const [formvalues, setFormvalues] = useState("");

    const [token, setToken] = useState("");
    const [file, setFile] = useState(""); // storing the uploaded file
    // storing the recived file from backend
    const [data, getFile] = useState({ name: "", path: "" });
    const [progress, setProgess] = useState(0); // progess bar
    const el = useRef(); // accesing input element

    const myRef = React.createRef();

    const recaptchaRef = React.createRef();
    const fields = ([
        {
            element: "config",
            formname: "Book a Valuation",
            error_text: "All fields are required",
            success_text: "Thank you for your interest, we are fetching buyers details",
            email_temp_user: "buyer_finder_user",
            email_temp_admin: "get_started_admin",
            email_subject_user: `Active Buyers Near You`,
            email_subject_admin: "Book a Valuation",
            email_server_func: "buyer_finder",
            event_tracking: "Buyer Finder",
            page_url: "/getstarted"
        },
        showemail && {
            grpmd: "12",
            label: "",
            placeholder: "Name*",
            name: "name",
            type: "text",
            element: "input",
            required: true,
            patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
            fieldClass: "",
            labelClass: "content_b-18"
        },
        showemail && {
            grpmd: "12",
            label: "",
            placeholder: "Email*",
            name: "email",
            type: "email",
            element: "input",
            required: true,
            patternchk: "[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
            class: ""
        },
        {
            element: "postcode",
        },
        {
            name: "Get Started",
            type: "submit",
            element: "button",
            value: "submit",
            step: "col-md-12 text-center",
            class: "btn btn-secondary btn-primary",
            formclass: ""
        },
        // {
        //   step: "col-md-12",
        //   text: 'By clicking Send Message, you agree to our <a href="/terms-and-conditions">Terms & Conditions</a>  and  <a href="/privacy-policy"> Privacy Policy </a>.',
        //   element: "html",
        //   class: "apply"
        // },
        {
            element: "captcha",
            class: "py-2 captcha-class",
            captchaRef: recaptchaRef
        },
    ]);

    const handlechange = event => {
        // remove initial empty spaces 
        event.target.value = event.target.value.trimStart()
    }



    useEffect(() => {
        const page_url = typeof window !== 'undefined' ? window.location.pathname : ''
        if (page_url == "/buyers-near-you" || page_url == "/buyers-near-you/") {
            setShowEmail(false)
        }
        
        $("#contact-form").show()
        setMainLoad(false)
        getIpAddress((ip) => {
            setIpAddress(ip)
        })

        const Buyerdata = async () => {
            if (localStorage.getItem('buyer-finder-postcode')) {
                setPostcode(localStorage.getItem('buyer-finder-postcode').replace(/\s/g, ''))
                // console.log("xxx", localStorage.getItem('buyer-finder-postcode').replace(/\s/g, ''))
                if (localStorage.getItem('buyer-finder-postcode').replace(/\s/g, '')) {

                    const home = await window.fetch(`https://wardsofkent-strapi-stage.q.starberry.com/buyerfinder`, {
                        method: `POST`,
                        body: JSON.stringify({ postcode: localStorage.getItem('buyer-finder-postcode').replace(/\s/g, '') }),

                    })

                    setBuyerResult(await home.json());

                    localStorage.removeItem('buyer-finder-postcode')
                }

            }
        }
        if (token !== '') {

            const processFromData = async () => {


                formvalues['g-recaptcha-response'] = token;
                const page_url = typeof window !== 'undefined' ? window.location.href : ''

                var gtm_client_id = '';
                var user_agent = '';
                if (typeof window !== 'undefined') {
                    user_agent = window.navigator.userAgent
                    if (typeof window.ga !== 'undefined') {
                        gtm_client_id = window.ga.getAll()[0].get('clientId');

                    }
                }

                let formData = new FormData();
                formvalues['name'] = formvalues.name;
                formvalues['email_subject_user'] = fields[0].email_subject_user;
                formvalues['email_subject_admin'] = fields[0].email_subject_admin;
                formvalues['referrer'] = page_url;
                formvalues['google_analytics_client_id'] = gtm_client_id;
                formvalues['user_agent'] = user_agent;
                formvalues['ip_address'] = ipAddress;
                formvalues['address'] = {
                    "stno": formvalues.home_stno,
                    "street": formvalues.home_street,
                    "district": formvalues.home_district,
                    "post_town": formvalues.home_post_town,
                    "county": formvalues.home_county,
                    "postcode": formvalues.home_postcode
                }
                formvalues['extra'] = JSON.stringify(formvalues);
                formData.append('data', JSON.stringify(formvalues));
                $("#contact-form").hide()
                setMainLoad(true)

                // postFormData(formData).then(async apiRes => {
                    window.grecaptcha.reset()
                    // lets send mail

                    // await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/functions`, {
                    //     method: `POST`,
                    //     mode: "no-cors",
                    //     headers: {
                    //         'Access-Control-Allow-Origin': '*',
                    //         "Content-Type": "application/x-www-form-urlencoded",
                    //     },
                    //     body: qs.stringify(formvalues),
                    // })

                    // if (apiRes.success === true) {
                        setFinalResponse(true)
                        Buyerdata()
                        setMainLoad(false)
                    // }
                    // .then(res => res.json())
                    // setServerResponse(response)


                    // const axiosOptions_email = {
                    //   url: '/api/server/' + fields[0].email_server_func,
                    //   method: "post",
                    //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
                    //   data: qs.stringify(formvalues),
                    // }

                    // axios(axiosOptions_email)
                    //   .then(response => {
                    //     console.log('mail sent!')
                    //   })
                    //   .catch(err =>
                    //     console.log(err)
                    //   );
                // });

                const url = typeof window !== 'undefined' ? window.location.href : ''
                if (props.title != "") {
                    fields[0].formname = props.title
                }
                // tracking event
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'formSubmit',
                    'formType': 'form-' + fields[0].event_tracking,
                    'formId': 'form-' + fields[0].event_tracking,
                    'formName': fields[0].event_tracking,
                    'formLabel': fields[0].event_tracking
                });

                setShowerror(false);
                setThankyou(true);
                // setTimeout(() => {
                //     $(".alert-success").show().delay(4000).fadeOut();
                // }, 500)
                myRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
            }
            processFromData();

        }
    }, [token]);

    const handleonVerify = token => {
        console.log("captcha verified");
        setToken(token);
        setShowerror(false);
    };

    const handleSubmit = event => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setShowerror(true);
            setValidated(true);
            setThankyou(false);
            myRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })

        }
        else {
            if (document.getElementById('home_postcode').value != "") {
            event.preventDefault();
            setShowerror(false);
            const formsdata = (event.target);
            const json = {}
            Object.keys(formsdata).map(key => (
                json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
            ))

            json['email_temp_user'] = fields[0].email_temp_user;
            json['email_temp_admin'] = fields[0].email_temp_admin;
            json['formname'] = fields[0].formname;
            json['g-recaptcha-response'] = token;


            json['files'] = file;
            setFile(file);
            setFormvalues(json);

            recaptchaRef.current.execute();
            // setToken("aaa")
            setValidated(false);

            // reset form
            const form = event.target
            if (finalresponse) {
                form.reset();
            }
        }
        else {
        event.preventDefault();
        event.stopPropagation();
        setErrorMsg("Please select a full address from the list");
        document.getElementById('postcode').value = ''
        setShowerror(true);
        setValidated(true);
        setThankyou(false);
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
        }
    };

    const url = typeof window !== 'undefined' ? window.location.href : ''
    var cookieData = getAllCookie();
    return (
        <React.Fragment>
            {showmainload &&
                <div className="form-wrapper loading-img">
                    <img src={LoadingImage} alt="" />
                </div>
            }
            {buyerresult ?
                <ChartResult form={formvalues} postcode={postcode} buyerresult={buyerresult} /> :
                <Container>
                    <h1 className="d-none">Buyer finder</h1>
                    <Row>
                        <Col md={12}>
                            <div className="buyer-finder-form" id="contact-form">
                                <div className="head">
                                    <h2>How many active buyers are looking for their new home near you right now?</h2>
                                    <p>To get started, simply enter your postcode below.</p>
                                </div>
                                <div className="get-started-form">
                                    <div ref={myRef} />

                                    <div className="form-group">
                                        {showerror && <div className="alert alert-danger">
                                            <p>{errormsg}</p>
                                        </div>}

                                        {/* {showthankyou && <div className="alert-success">
                                            <p>{fields[0].success_text}</p>
                                        </div>} */}
                                    </div>
                                    <Form className="form" autocomplete="off" name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onkeydown="return event.key != 'Enter';" onSubmit={handleSubmit}>
                                        <input type="hidden" name="form_name" value={fields[0].formname} />
                                        <input type="hidden" name="form_type" value={fields[0].form_type} />
                                        <input type="hidden" name="to_email_id" value={props.to_email_id ? props.to_email_id : ''} />
                                        <input type="hidden" name="bot-field" />
                                        <input type="hidden" name="form_type_id" value="5" />
                                        <input type="hidden" name="utm_source" value={cookieData.utm_source} />
                                        <input type="hidden" name="utm_campaign" value={cookieData.utm_campaign} />
                                        <input type="hidden" name="utm_term" value={cookieData.utm_term} />
                                        <input type="hidden" name="utm_content" value={cookieData.utm_content} />
                                        <input type="hidden" name="utm_medium" value={cookieData.utm_medium} />

                                        <input type="hidden" name="form_page" value={url} />
                                        <input type="hidden" name="brand_id" value={process.env.GATSBY_STRAPI_BRAND_ID} />
                                        {fields.map((field, index) => {
                                            if ("input" === field.element) {
                                                return (
                                                    <InputField
                                                        name={field.name}
                                                        grpmd={field.grpmd}
                                                        ref={field.ref}
                                                        type={field.type}
                                                        fieldClass={field.class}
                                                        placeholder={field.placeholder}
                                                        label={field.label}
                                                        labelClass={field.labelClass}
                                                        required={field.required}
                                                        key={`${field.element}~${index}`}
                                                        pattern={field.patternchk}
                                                        handlechange={handlechange}
                                                    />
                                                );
                                            }
                                            if ("file" === field.element) {
                                                return (
                                                    <FileField
                                                        name={field.name}
                                                        grpmd={field.grpmd}
                                                        ref={field.ref}
                                                        label={field.label}
                                                        type={field.type}
                                                        fieldClass={field.fieldClass}
                                                        labelClass={field.labelClass}
                                                        placeholder={field.placeholder}
                                                        key={`${field.element}~${index}`}
                                                        accept={field.accept}
                                                        // handlechange={handleAttachment}
                                                        required={field.required}
                                                    />
                                                );
                                            }
                                            if ("select" === field.element) {
                                                return (
                                                    <SelectField
                                                        name={field.name}
                                                        id={field.id}
                                                        grpmd={field.grpmd}
                                                        label={field.label}
                                                        ref={field.ref}
                                                        required={field.required}
                                                        fieldClass={field.class}
                                                        placeholder={field.placeholder}
                                                        values={field.values}
                                                        key={`${field.element}~${index}`}
                                                        handlechange={handlechange}
                                                        componentprops={props}
                                                    />
                                                );
                                            }
                                            if ("textarea" === field.element) {
                                                return (
                                                    <TextAreaField
                                                        name={field.name}
                                                        grpmd={field.grpmd}
                                                        ref={field.ref}
                                                        rows={field.rows}
                                                        fieldClass={field.class}
                                                        label={field.label}
                                                        labelClass={field.labelClass}
                                                        placeholder={field.placeholder}
                                                        required={field.required}
                                                        key={`${field.element}~${index}`}
                                                        handlechange={handlechange}
                                                    />
                                                );
                                            }
                                            if ("checkbox" === field.element) {
                                                return (
                                                    <CheckboxField
                                                        name={field.name}
                                                        ref={field.ref}
                                                        value={field.value}
                                                        fieldClass={field.class}
                                                        placeholder={field.placeholder}
                                                        required={field.required}
                                                        key={`${field.name}~${index}`}
                                                        handlechange={handlechange}
                                                    />
                                                );
                                            }
                                            if ("postcode" === field.element) {
                                                return (
                                                    <div className="text   form-group col-md-12">
                                                        <PostcodeLookUp />
                                                    </div>
                                                )
                                            }
                                            if ("html" === field.element) {
                                                return (
                                                    <HtmlBox
                                                        text={field.text}
                                                        step={field.step}
                                                        fieldClass={field.class}
                                                        key={`${field.element}~${index}`}
                                                    />
                                                );
                                            }
                                            if ("captcha" === field.element) {
                                                return (
                                                    <ReCaptchaBox
                                                        fieldClass={field.class}
                                                        captRef={field.captchaRef}
                                                        key={`${field.element}~${index}`}
                                                        handleonVerify={handleonVerify}
                                                    />
                                                );
                                            }
                                            if ("button" === field.element) {
                                                return (
                                                    <Form.Group>
                                                        <ButtonField
                                                            name={field.name}
                                                            fieldClass={field.class}
                                                            step={field.step}
                                                            formclass={field.formclass}
                                                            type={field.type}
                                                            value={field.value}
                                                            key={`${field.element}~${index}`}
                                                        />
                                                    </Form.Group>
                                                );
                                            }
                                        })
                                        }
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>}
        </React.Fragment>
    );
}


const GetStartedPage = (props) => (
    <GetStartedForm classone={props.classone} to_email_id={props.to_email_id} title={props.title} />
)

export default GetStartedPage