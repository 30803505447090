import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby";
// import { useQuery } from '@apollo/client';
// import gql from "graphql-tag"
import ModalTiny from "react-bootstrap/Modal"
import _ from "lodash"
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import Select, { components } from 'react-select';
import { useWindowSize } from '../../hooks/winodw-size'

import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from "gatsby";
import $ from "jquery"
import Enquiry from "./BranchEnquiry";
import NoImage from "../../images/douglas-no-image.png"
import "../AreaGuideDetails/News/News.scss"
import "./Branches.scss";
import BranchStructuredData from "../Seo/BranchStructuredData"
import logoBlack from "../../images/logo-dark.svg";

import GoogleAutoComplete from "../common/google-places/GoogleAutocomplete";
// import LoadExternalScript from "../../components/utils/load-external-script";
import { BRANCHES_PAGE_URL } from "../common/site/constants"

import { inViewOptions1, containerVariants, contentItemStagger, contentItem, fadeInFromTop } from '../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import BranchAwardComponent from "./branch-awards";

// const googleMapScript = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB5ZVyLwvhU9aEunBIHXmImBji787VvheY&libraries=places';

const postsPerPage = 16;
let arrayForHoldingPosts = [];

const Branches = () => {

    const data = useStaticQuery(graphql`
    query {
        allBranches(
            filter: {brand_id: {eq: "da"}, publish: {eq: true}}
            sort: {fields: branch_name, order: ASC}
          ) {
            edges {
              node {
                branch_name
                branch_id
                brand_id
                slug
                _id
                area_tag
                address {
                  building
                  street
                  district
                  town
                  county
                  postcode
                  latitude
                  longitude
                }
                sales_dept {
                  intro
                  phone
                  email
                }
                lettings_dept {
                  intro
                  phone
                  email
                }
                image {
                  srcUrl
                  title
                  url
                  last_modified
                  createdAt
                }
                Awards {
                    id
                    Title
                    Link
                    ShowBranchPage
                    ShowBranchFinder
                    Image {
                        alternativeText
                        url
                    }          
                }
                description
                imagetransforms {
                  image_Transforms
                }
                areas_covered
              }
            }
          }
    }
  `);

    const allbranches = data.allBranches.edges;


    const [category, setCategory] = React.useState("all")
    const [filteredTeam, setFilteredTeam] = useState([]);
    const [postsToShow, setPostsToShow] = useState([]);
    const [areaList, setAreas] = useState([]);
    const [next, setNext] = useState(16);
    const [filterupdate, setFilterUpdate] = React.useState(false)
    const [search_text, setSearchText] = useState('');
    const [loadtext, setLoadText] = useState('');
    const [catupdate, setCatUpdate] = React.useState(1)
    const [selectedArea, setSelectedArea] = useState([{ value: 'all', label: 'All Areas' }]);
    const [windowWidth] = useWindowSize();
    const [isPlay, setPlay] = useState(false);
    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const [addressObj, setAddressObj] = useState([]);
    const [renderComponent, setRenderComponent] = useState(false);
    

    useEffect(() => {
        window.addEventListener("mousemove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        }) 
    })
    const updateAddressObj = (val) => {
        setAddressObj(val)
    }

    const showFilter = () => {
        setShow(!show);
    };

    const BranchFilter = (url) => {
        setCatUpdate(2);
        setCategory(url)
        setFilterUpdate(true)
        setNext(16)
        setSearchText("");
        document.getElementById('search').value = "";
    }

    const handlechange = (event) => {
        // remove initial empty spaces
        setCatUpdate(2)
        // var search_val = event.target.value.trimStart();        
        var search_val = document.getElementById('search').value;
        if(search_val != ""){
            if(addressObj.length ==0){
                setShowError(true);               
                return;
            }
            setSearchText(search_val);
            setFilterUpdate(true) 
        }
        else
        {
            setShowError(true);               
            return;
        }
               
    }

    const handlechangeOne = (event) => {
        // remove initial empty spaces
        if (event.key == "Enter") {   
            setCatUpdate(2)
            var search_val = document.getElementById('search').value;            
            if(search_val == ""){
                setShowError(true);               
                return;
            }else{               
                if(typeof addressObj.geometry === "undefined"){
                    // console.log('comes');
                    setShowError(true);               
                    return;
                }else{
                    setSearchText(search_val);
                    setFilterUpdate(true)         
                }
            }
            
            }
    }

    const filteredList = () => {
        // setFilteredTeam([])
        var filteredTeamList = []
        var rawTeamList =[];
        setShowError(false); 
      
        if (catupdate == 2) {
            setCatUpdate(3)
        }
        if (category == "all" && search_text == '') {
            filteredTeamList = allbranches
        }
        else {
            if( addressObj.length !=0 && typeof addressObj.geometry !== "undefined" && typeof addressObj.geometry.viewport !== "undefined") {
                var origin = JSON.stringify(addressObj.geometry.location)
                origin = JSON.parse(origin)
                let jsonViewport=JSON.stringify(addressObj.geometry.viewport)
                let viewport=JSON.parse(jsonViewport)
                var r_earth = 6378;
                var pi = Math.PI, cos = Math.cos;
                var miles=5;
                // 5 miles search algoritham
                let north = viewport.north + (miles / r_earth) * (180 / pi);
                let south = viewport.south - (miles / r_earth) * (180 / pi);
                let east = viewport.east + (miles / r_earth) * (180 / pi) / cos(viewport.north * pi / 180);
                let west =  viewport.west - (miles / r_earth) * (180 / pi) / cos(viewport.south * pi / 180);
            
                for (var i = 0; i < allbranches.length; i++) {
                    let latitude = allbranches[i].node.address?.latitude ? allbranches[i].node.address?.latitude : ''
                    let longitude = allbranches[i].node.address?.longitude ? allbranches[i].node.address?.longitude : ''
                    // To getting all branches inside the search location viewport
                    // if ((viewport.south <= latitude && viewport.north >= latitude) && (viewport.west <= longitude && viewport.east >= longitude)) {
                    if ((south <= latitude && north >= latitude) && (west <= longitude && east >= longitude)) {
                        filteredTeamList.push(allbranches[i])
                        rawTeamList.push(allbranches[i])                        
                    }                            
                }                   
                //Finding branch distance from the search location
                setPostsToShow(rawTeamList);
                if (rawTeamList.length != 0) {
                    findBranchDistance(origin,rawTeamList);               
                }
                setCategory('')
                setAddressObj([])                                    
            }
            else {
                setFilteredTeam([])

                // filteredTeamList = branches.filter(branch => {
                //     let main_category = branch.node.Choose_Area.filter(cat => _.includes(cat, category));
                //     return main_category.length > 0
                // });
                filteredTeamList = allbranches.filter(branch => {
                    if (branch.node.area_tag) {
                        if (_.includes(JSON.parse(branch.node.area_tag), category)) {
                            return branch;
                        }
                    }
                });                  
            }
        }
        if(rawTeamList.length == 0){
            if (filteredTeamList.length == 0) {
                setLoadText("Sorry, no branches found")
            }       
            setFilteredTeam(filteredTeamList)
            setFilterUpdate(false)
        }    
    }

    const loopWithSlice = (start, end) => {
        const slicedPosts = filteredTeam.slice(start, end);
        arrayForHoldingPosts = [...slicedPosts];
        setPostsToShow(arrayForHoldingPosts);
    };

    const loopWithSliceMore = (start, end) => {
        const slicedPosts = filteredTeam.slice(start, end);

        arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
        setPostsToShow(arrayForHoldingPosts);
    };

    const handleShowMorePosts = () => {
        loopWithSliceMore(next, next + postsPerPage);
        setNext(next + postsPerPage);
    };

    useEffect(() => {
        if ((catupdate == 1 || catupdate == 2) && (filteredTeam.length == 0 || filterupdate)) {
            filteredList()
        }
        loopWithSlice(0, postsPerPage);
        if (_.isEmpty(areaList) && allbranches.length > 0) {
            let areaList = [{ value: 'all', label: 'All Areas' }];

            allbranches.map(({node}, i) => {
                var areatg = node.area_tag ? JSON.parse(node.area_tag) : ''
                if (areatg && areatg.length > 0) {
                    areatg.map((area, i) => {
                        areaList.push({ value: area, label: area })
                    })
                }
            })

            setAreas(_.uniqBy(areaList, 'value'))
        }

        // iphone zoom issue when type a text in input field
        $('head').append('<meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no, maximum-scale=1.0, user-scalable=0">');
        // iphone zoom issue when type a textin input field
    }, [data, filteredTeam, category, search_text]);


    const customStyles = {
        control: styles => ({
            ...styles, outline: 0, border: 'none', boxShadow: 'none', borderRadius: '8px', backgroundColor: '#CCE7EE',
            color: 'red', fontWeight: 'bold', height: "48px", width: "100%", paddingLeft: "10px", paddingRight: "10px"
        }),
        singleValue: base => ({
            ...base,
            color: '#005670'
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#005670", // Custom colour
            fontSize: "24px"
        })

    }
    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                {props.selectProps.menuIsOpen ? '-' : '+'}
            </components.DropdownIndicator>
        );
    };

    // Finding the branch distance using google distance matrix
    const  findBranchDistance = async(origins,rawTeamList) => {       
        var origin = new window.google.maps.LatLng(origins.lat, origins.lng);
        var sortTeamList=[];
        var destinations=[];
        for (var i = 0; i < rawTeamList.length; i++) {        
            let lat = allbranches[i].node.address?.latitude ? allbranches[i].node.address?.latitude : '';
            let long = allbranches[i].node.address?.longitude ? allbranches[i].node.address?.longitude : '';
            destinations.push(new window.google.maps.LatLng(lat,long))
            //Distance API can find only 25 destination distance on single API call. So creating batch call with 25 locations.
            if(destinations.length == 25 || i+1 == rawTeamList.length){  
               var locData = await getDistance(origin, destinations,rawTeamList); // Trigger API call
               sortTeamList=sortTeamList.concat(locData); // After result merging values into main array             
               destinations=[]; // After getting distance empty the batch array                        
            }
        }    
        sortTeamList = sortTeamList.sort((a, b) => a.distance.value - b.distance.value); // sort array based on closest distance
        setFilteredTeam(sortTeamList) //set filtered value 
        setFilterUpdate(false)             
     };

     // Google Distance API call trigger
     const getDistance = (origin,destinations,rawTeamList) => { 
        let deferred = new $.Deferred();
        var distTeamList=[];
        let service = new window.google.maps.DistanceMatrixService(); // Distance API server init
        service.getDistanceMatrix({
            origins: [origin], 
            destinations: destinations,
            travelMode: window.google.maps.TravelMode.DRIVING
        }, function(response, status) {
            //If request success append distance details to the location
            if (status == window.google.maps.DistanceMatrixStatus.OK) {
                    for (let i = 0 ; i < response.rows[0].elements.length ; i++) {
                        var item = {
                            ...rawTeamList[i],
                            ...response.rows[0].elements[i]
                        };
                        distTeamList.push(item);
                    }
                deferred.resolve(distTeamList);
            } else {
                deferred.reject(status);
            }
        })
        return deferred.promise();
     };

    return (
        <InView  {...inViewOptions1}>
            {({ ref, inView }) => (
                <motion.section
                    className="similar-property"
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={containerVariants}>
                    <BranchStructuredData />
                    <Container>
                    {/* <LoadExternalScript
                            src={googleMapScript}
                            async="true"
                            defer="true"
                            // loadScript={useEffect}
                        appendScriptTo="head"
                        /> */}
                        <Row>

                            <div className="news-wrap">
                                <Container>
                                    <Row>
                                        <Col md="12">
                                            <div className="tab-wrap">
                                                <div className="d-none d-lg-flex">
                                                    {/* <button type="button" className={`all-btn ${category === 'all' && "active"}`} onClick={() => { BranchFilter('all'); showFilter(); }}>All Areas <span>{show ? "-" : "+"}</span></button> */}
                                                    <div className={show ? "tabing-list d-block" : "tabing-list "} >
                                                        <ul className="area-guide-listing-desktop">
                                                            <li>
                                                                <a href="javascript:void(0);" className={`all-btn ${category === 'all' && "active"}`} onClick={() => { BranchFilter('all'); showFilter(); }}>All Areas</a>
                                                            </li>
                                                            {areaList.map((area, index) => (
                                                                <React.Fragment>
                                                                    {area.value !== "all" &&
                                                                        <li>
                                                                            <a href="javascript:void(0);" className={`${category === area.value && "active"}`} onClick={() => { BranchFilter(area.value); }}>{area.label}</a>
                                                                        </li>
                                                                    }
                                                                </React.Fragment>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="d-block d-lg-none">
                                                    <Select
                                                        name={`area`}
                                                        value={selectedArea}
                                                        options={areaList}
                                                        className={``}
                                                        onChange={(selected) => {
                                                            setSelectedArea(selected)
                                                            BranchFilter(selected.value);
                                                        }}
                                                        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                                                        classNamePrefix="select branch-select-mobile-dropdown"
                                                        styles={customStyles}
                                                    />
                                                </div>
                                                <div className="form news-filter">
                                                    {/* <i className="icon-search"></i> */}
                                                    {/* {windowWidth < 768 && <Form.Control id="search" onKeyDown={handlechangeOne} placeholder="Search..." />} */}
                                                    {/* {windowWidth >= 768 && <Form.Control id="search" onKeyDown={handlechangeOne} placeholder="Search a branch" />} */}
                                                    {renderComponent &&
                                                     <GoogleAutoComplete updateAddressObj={updateAddressObj} showError={showError} setShowError={setShowError} handlechangeOne={handlechangeOne}/>    
                                                    }
                                                    <button type="submit" className="search-btn" onClick={handlechange}> <i className="icon-search"></i></button>
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>
                                </Container>
                            </div>
                            {(postsToShow.length == 0) &&
                                <p className="no-results-found">Oops! No branches could be found but, with over 40 offices across East London and Essex, we're sure to have a friendly team local to you. Call us on <a href="tel:0800 093 2271">0800 093 2271</a> to chat today.</p>
                            }

                            {postsToShow.map(({node}, index) => {
                                const image_url = node.image[0]?.url;
                                let processedImages = JSON.stringify({});
                                if (node.imagetransforms?.image_Transforms) {
                                    processedImages = node.imagetransforms.image_Transforms;
                                }

                                var address = ''
                                if (node.address.building) {
                                    address += node.address.building
                                }
                                if (node.address.street) {
                                    address += ", " + node.address.street
                                }
                                if (node.address.town) {
                                    address += ", " + node.address.town
                                }
                                if (node.address.county) {
                                    address += ", " + node.address.county
                                }
                                if (node.address.postcode) {
                                    address += ", " + node.address.postcode
                                }

                                return (
                                    <Col lg={3} md={6}>
                                        {/* <motion.div key={index} custom={index + 1} variants={fadeInFromTop} className="properties-wrapper"> */}
                                        <div key={index} custom={index} variants={fadeInFromTop} className="properties-wrapper">
                                            <Link to={`${BRANCHES_PAGE_URL.alias}/estate-agents-in-${node.slug}/`} className="link">
                                            <div className="property-img">
                                                    {node.image?.length > 0 ?
                                                            <ImageTransform
                                                                imagesources={node.image[0]?.url}
                                                                renderer="srcSet"
                                                                imagename="branch.image.finder"
                                                                attr={{ alt: `${node?.branch_name} Branch - Douglas Allen`, class: '' }}
                                                                imagetransformresult={processedImages}
                                                                id={node._id}
                                                                ggfxName="FEED"
                                                            />
                                                         :
                                                            <picture>
                                                                <img src={NoImage} alt={`${node.branch_name} - Douglas Allen`} />
                                                            </picture>
                                                    }
                                                </div>
                                            </Link>
                                            <div className="property-info contact-info-title">
                                                <Link to={`${BRANCHES_PAGE_URL.alias}/estate-agents-in-${node.slug}/`} className="link">
                                                    <div className="title">
                                                        <h2>
                                                            {node.branch_name}
                                                        </h2>
                                                        <Link href="#">
                                                            <i className="icon-arrow"></i>
                                                        </Link>
                                                    </div>
                                                    {node.address &&
                                                        <address>
                                                            {address.replace(/^[,\s]+|[,\s]+$/g, '').replace(/,[,\s]*,/g, ',')}                                                           
                                                        </address>
                                                    }                                                
                                                </Link>
                                                {node.sales_dept && node.sales_dept.phone &&
                                                    <div className="connect-us">
                                                        <span className="sm-title">Sales:</span>
                                                        <a className="tel" href={`tel:${node.sales_dept?.phone}`}>
                                                            {node.sales_dept?.phone}
                                                        </a>&nbsp;
                                                        <Enquiry name={node.Name} class="mail" branch={'Branch Contact'}> / Email </Enquiry>
                                                        <br></br>
                                                    </div>
                                                }
                                                {node.lettings_dept && node.lettings_dept.phone &&
                                                    <div className="connect-us">
                                                        <span className="sm-title">Lettings: </span>
                                                        <a className="tel" href={`tel:${node.lettings_dept?.phone}`}>
                                                            {node.lettings_dept?.phone}
                                                        </a>&nbsp;
                                                        <Enquiry name={node.Name} class="mail" branch={'Branch Contact'}> / Email </Enquiry>
                                                    </div>
                                                }
                                                {node.Awards && node.Awards.length > 0 &&
                                                <>
                                                    {node.Awards.map((award, i) => {
                                                        if(award.ShowBranchFinder){
                                                            return (<BranchAwardComponent key={i} award={award} slug={node.slug} />)
                                                        }
                                                    })
                                                    }
                                                </> 
                                                }                                             
                                            </div>
                                        </div>
                                        {/* </motion.div> */}
                                    </Col>
                                )
                            })}

                            {arrayForHoldingPosts.length !== filteredTeam.length ?
                                <div className="area-guide-load-more people-landing-load-more"><button className="btn btn-pagination" onClick={() => handleShowMorePosts()}>Load more</button></div>
                                : ''
                            }
                        </Row>
                    </Container>
                </motion.section>
            )}
        </InView>
    )
}
export default Branches
