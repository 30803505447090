import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Dropdown } from 'react-bootstrap';
import axios from "axios"
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

import BuyerForm from "../../forms/buyer-finder-main-form"
import BuyerFinder from "../../forms/buyer-finder-cta-submit"
import $ from 'jquery/dist/jquery.min.js'

import ScrollAnimation from 'react-animate-on-scroll';
// import PieChart from "../../../images/development-details/pie-chart.svg";

import {
    isTablet,
    isIPad13,
    isIOS13,
    isMobile,
    isDesktop,
} from "react-device-detect";

import "./ChartArea.scss"
// markup

const COLORS = ['#4d676f', '#93cbdc', '#7394c6', '#e9c873', '#93aa61'];


const ChartArea = (props) => {

    const [data, setData] = useState([]);

    useEffect(() => {
        var target = $("body");
        $('html,body').animate({
            scrollTop: target.offset().top
        }, 100);

        setData([
            { name: 'First time buyer' },
            { name: 'Under offer' },
            { name: 'Cash buyer' },
            { name: 'Own to sell' },
            { name: 'Nothing to sell' },
        ])


        if (props.buyerresult?.buyers?.length > 0) {
            $("#no-result-hide").remove();
        }
        setTimeout(function () { $("#no-result-hide").remove() }, 3000);

    }, [props.postcode]);

    return (
        <React.Fragment>
            {props.buyerresult?.buyers?.length > 0 ?
                <div className="chart-area">
                    <Container>
                        <Row className="align-items-lg-center">
                            <Col lg="6">
                                <PieChart width={(isTablet || isMobile) ? 350 : 500} height={(isTablet || isMobile) ? 350 : 500}>
                                    <Pie
                                        data={[
                                            { name: 'First time buyer', value: parseInt(props.buyerresult.buyers[0]?.first_time_buyers) },
                                            { name: 'Under offer', value: parseInt(props.buyerresult.buyers[0]?.under_offer) },
                                            { name: 'Cash buyer', value: parseInt(props.buyerresult.buyers[0]?.cash_buyers) },
                                            { name: 'Own to sell', value: parseInt(props.buyerresult.buyers[0]?.own_to_sell) },
                                            { name: 'Nothing to sell', value: parseInt(props.buyerresult.buyers[0]?.nothing_to_sell) },
                                        ]}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={true}
                                        // label={renderCustomizedLabel}
                                        outerRadius={(isTablet || isMobile) ? 150 : 250}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {data.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                </PieChart>
                            </Col>
                            <Col lg="6">
                                <div className="chart-content">
                                    <h2>We currently have {props.buyerresult.total.toLocaleString()} buyers looking for property in {props.buyerresult.postcode}.
                                        {/* We currently have<br/> <span>667 buyers</span> looking for a property in this area. */}
                                    </h2>
                                    <p>Your property is in a high demand area and Douglas Allen are here to help you on you move forwards. With exclusive access to active buyers in your area, we can find the best buyer for you.</p>

                                    <p>With the market seeing the unprecedented levels of activity that it is right now, there’s never been a better time to sell your home!</p>
                                    <ul className="chart-point">
                                        <li>
                                            <i className=""></i> <span>{props.buyerresult.buyers[0]?.first_time_buyers}</span> First time buyer
                                        </li>
                                        <li>
                                            <i className=""></i> <span>{props.buyerresult.buyers[0]?.under_offer}</span> Under offer
                                        </li>
                                        <li>
                                            <i className=""></i> <span>{props.buyerresult.buyers[0]?.cash_buyers}</span> Cash buyer
                                        </li>
                                        <li>
                                            <i className=""></i> <span>{props.buyerresult.buyers[0]?.own_to_sell}</span> Own to sell
                                        </li>
                                        <li>
                                            <i className=""></i> <span>{props.buyerresult.buyers[0]?.nothing_to_sell}</span> Nothing to sell
                                        </li>
                                    </ul>

                                    <p className="highlight-content">
                                        <strong>Are you interested in finding out the value of your home?</strong> Choose one of the options below to get started.
                                    </p>

                                    <div className="btn-wrap">
                                        <Link to="/sell-your-property/property-valuation" className="btn btn-secondary">
                                            <span>Quick and approximate</span>
                                            Instant Valuation
                                        </Link>
                                        <BuyerFinder {...props.form} id="5" />
                                        {/* <Link to="/sell-your-property/property-valuation" className="btn">
                                            <span>In-person and accurate</span>
                                            Home Visit Valuation
                                        </Link> */}
                                    </div>
                                    <div className="cta-buyer-finder-thanks alert alert-success" id="cta-buyer-finder-thanks">
                                        Thank you for your interest. A member of our team will contact you shortly.
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div> :
                <React.Fragment>
                    <h2 id="no-result-hide">Sorry, no buyers looking for property in <span>{props.postcode}</span>.</h2>
                    <BuyerForm />
                </React.Fragment>
            }
        </React.Fragment>
    )
}
export default ChartArea