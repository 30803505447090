import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby";
import ModalTiny from "react-bootstrap/Modal"
import Select, { components } from 'react-select';
import _ from "lodash"
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import { AREA_GUIDE_PAGE_URL } from "../common/site/constants"


import { useWindowSize } from '../../hooks/winodw-size'

import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from "gatsby";
import { NewsDate } from "../common/site/functions"
import PlayVideo from '../Play/CustomVideo';
import FormBlock from '../Home/FormBlock/FormBlock';
import NoImage from "../../images/douglas-no-image.png"
import "../Home/GetKnow/GetKnow.scss";

// import "./Branches.scss";


import { inViewOptions1, containerVariants, contentItemStagger, contentItem, fadeInFromTop } from '../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const postsPerPage = 16;
let arrayForHoldingPosts = [];


const NewsLanding = () => {

  const data = useStaticQuery(graphql`
  query {
    glstrapi {
        areaGuides(
            publicationState: LIVE
            where: {Publish: true}
            sort: "Name:asc"
          ) {
            id
            Name
            URL
            Tile_Image {
              url
              alternativeText
            }
            Area {
              Name
              URL
            }
            imagetransforms
        }

      newsCategories(sort: "Sort:asc") {
        Name
        Sort
        URL
        all_news {
          Title
          URL
        }
      }

      areas(sort: "Sort:asc") {
        Name
        Sort
        URL
        area_guides {
            id
        }
      }

    }
  }
`);

  const allnews = data.glstrapi.areaGuides;
  const areas = data.glstrapi.areas;
  const [category, setCategory] = React.useState("all")
  const [filteredTeam, setFilteredTeam] = useState([]);
  const [postsToShow, setPostsToShow] = useState([]);
  const [next, setNext] = useState(16);
  const [filterupdate, setFilterUpdate] = React.useState(false)
  const [search_text, setSearchText] = useState('');
  const [loadtext, setLoadText] = useState('');
  const [catupdate, setCatUpdate] = React.useState(1)

  const [areaList, setAreas] = useState([]);
  const [windowWidth] = useWindowSize();
  const [selectedArea, setSelectedArea] = useState([{ value: 'all', label: 'All Areas' }]);
  const [isPlay, setPlay] = useState(false);
  const [show, setShow] = useState(false);
  const showFilter = () => {
    setShow(!show);
  };


  const BranchFilter = (url) => {
    setCatUpdate(2);
    setCategory(url)
    setFilterUpdate(true)
    setNext(16)
    var search_val = document.getElementById('search').value
    setSearchText(search_val);
  }

  const handlechange = (event) => {
    // remove initial empty spaces
    setCatUpdate(2)
    // var search_val = event.target.value.trimStart();
    var search_val = document.getElementById('search').value
    setSearchText(search_val);
    setFilterUpdate(true)
  }

  const handlechangeOne = (event) => {
    // remove initial empty spaces
    if (event.key == "Enter") {
      setCatUpdate(2)
      // var search_val = event.target.value.trimStart();
      var search_val = document.getElementById('search').value
      setSearchText(search_val);
      setFilterUpdate(true)
    }
  }

  const filteredList = () => {
    setFilteredTeam([])
    var filteredTeamList = []
    if (catupdate == 2) {
      setCatUpdate(3)
    }
    if (category == "all" && search_text == '') {
      filteredTeamList = allnews
    }
    else if (category != "all" && search_text != '') {
      for (var i = 0; i < allnews.length; i++) {
        var allcats = allnews[i].Area
        let main_category = allcats.filter(cat => _.includes(cat, category));
        var full_name = allnews[i].Name.toLowerCase()
        if (full_name.includes(search_text.toLowerCase()) && (main_category.length > 0)) {
          filteredTeamList.push(allnews[i])
        }
      }
    }
    else if (category == "all" && search_text != '') {
      for (var i = 0; i < allnews.length; i++) {
        var full_name = allnews[i].Name.toLowerCase()
        if (full_name.includes(search_text.toLowerCase())) {
          filteredTeamList.push(allnews[i])
        }
      }
    }
    else if (category != "all" && search_text == '') {
      filteredTeamList = allnews.filter(branch => {
        let main_category = branch.Area.filter(cat => _.includes(cat, category));
        return main_category.length > 0
      });
    }
    if (filteredTeamList.length == 0) {
      setLoadText("Sorry, No results found")
    }
    setFilteredTeam(filteredTeamList)
    setFilterUpdate(false)
  }

  const loopWithSlice = (start, end) => {
    const slicedPosts = filteredTeam.slice(start, end);
    arrayForHoldingPosts = [...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  const loopWithSliceMore = (start, end) => {
    const slicedPosts = filteredTeam.slice(start, end);

    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  const handleShowMorePosts = () => {
    loopWithSliceMore(next, next + postsPerPage);
    setNext(next + postsPerPage);
  };

  useEffect(() => {
    if ((catupdate == 1 || catupdate == 2) && (filteredTeam.length == 0 || filterupdate)) {
      filteredList()
    }
    loopWithSlice(0, postsPerPage);
    if (_.isEmpty(areaList)) {
      let areaList = [{ value: 'all', label: 'All Areas' }];
      areas.map((area) => {
        if (area.area_guides.length > 0) {
          areaList.push({ value: area.URL, label: area.Name })
        }
      })

      setAreas(areaList)
    }
  }, [filteredTeam, category, search_text]);


  const customStyles = {
    control: styles => ({
      ...styles, outline: 0, border: 'none', boxShadow: 'none', borderRadius: '8px', backgroundColor: '#CCE7EE',
      color: 'red', fontWeight: 'bold', height: "48px", width: "100%", paddingLeft: "10px", paddingRight: "10px"
    }),
    singleValue: base => ({
      ...base,
      color: '#005670'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#005670", // Custom colour
      fontSize: "24px"
    })

  }
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? '-' : '+'}
      </components.DropdownIndicator>
    );
  };

  const ImageRender = ({ image_url, node, processedImages }) => {
    return (
      <ImageTransform imagesources={image_url} renderer="srcSet" imagename="area-guides.Tile_Image.tile" attr={{ alt: `${node.Name} Area Guide - Douglas Allen`, class: '' }} imagetransformresult={processedImages} id={node.id} format="source" />
    )
  }

  return (
    <InView  {...inViewOptions1}>
      {({ ref, inView }) => (
        <motion.section
          className="similar-property area-guide-landing-page"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}

        >
          <Container>
            <Row>

              <div className="news-wrap">
                <Container>
                  <Row>
                    <Col md="12">
                      <div className="tab-wrap">
                        <div className="d-none d-lg-flex">
                          {/* <button type="button" className={`all-btn ${category === 'all' && "active"}`} onClick={() => { BranchFilter('all'); showFilter(); }}>All Areas <span>{show ? "-" : "+"}</span></button> */}
                          <div className={show ? "tabing-list d-block" : "tabing-list "} >
                            <ul className="area-guide-listing-desktop">
                              <li>
                                <a href="javascript:void(0);" className={`all-btn ${category === 'all' && "active"}`} onClick={() => { BranchFilter('all'); showFilter(); }}>All Areas</a>
                              </li>
                              {areas.map((area, index) => (
                                <React.Fragment>
                                  {area.area_guides.length > 0 &&
                                    <li>
                                      <a href="javascript:void(0);" className={`${category === area.URL && "active"}`} onClick={() => { BranchFilter(area.URL); }}>{area.Name}</a>
                                    </li>
                                  }
                                </React.Fragment>
                              ))}
                            </ul>
                          </div>
                        </div>

                        <div className="d-block d-lg-none">
                          <Select
                            name={`area`}
                            value={selectedArea}
                            options={areaList}
                            className={``}
                            onChange={(selected) => {
                              setSelectedArea(selected)
                              BranchFilter(selected.value);
                            }}
                            components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                            classNamePrefix="select branch-select-mobile-dropdown"
                            styles={customStyles}
                          />
                        </div>
                        <div className="form news-filter">
                          {/* <i className="icon-search"></i> */}
                          {windowWidth < 768 && <Form.Control id="search" onKeyDown={handlechangeOne} placeholder="Search..." />}
                          {windowWidth >= 768 && <Form.Control id="search" onKeyDown={handlechangeOne} placeholder="Search by area name" />}
                          <button type="submit" className="search-btn" onClick={handlechange}> <i className="icon-search"></i></button>
                        </div>

                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>

              {postsToShow.length == 0 &&
                <p className="no-results-found">{loadtext}</p>
              }

              <div className="other-areas-block">
                <div className="other-areas-list">
                  <motion.div variants={fadeInFromTop}>
                    <Row>

                      {postsToShow.map((item, index) => {

                        const image_url = item.Tile_Image.url;

                        let processedImages = JSON.stringify({});
                        if (item.imagetransforms?.Tile_Image_Transforms) {
                          processedImages = item.imagetransforms.Tile_Image_Transforms;
                        }

                        

                        return (
                          <>
                            {item.Name && item.URL &&
                              <Col xl={3} md={6}>
                                <Link to={`${AREA_GUIDE_PAGE_URL.alias}${item.URL}/`} className="img-link">
                                  <div className="other-areas-item img-zoom">
                                    <div className="other-areas-img">
                                      {item.Tile_Image ?
                                        <ImageRender processedImages={processedImages} image_url={image_url} node={item} />
                                        :
                                        <img src={NoImage} alt={item.Name} />
                                      }

                                    </div>
                                    <Link to={`${AREA_GUIDE_PAGE_URL.alias}${item.URL}/`} class="btn btn-white">{item.Name} <i className="icon-arrow"></i></Link>
                                  </div>
                                </Link>
                              </Col>
                            }
                          </>
                        )
                      })}

                    </Row>
                  </motion.div>
                </div>
              </div>



              {arrayForHoldingPosts.length !== filteredTeam.length ?
                <div className="area-guide-load-more people-landing-load-more"><button className="btn btn-pagination" onClick={() => handleShowMorePosts()}>Load more</button></div>
                : ''
              }
            </Row>
          </Container>
        </motion.section>
      )}
    </InView>
  )
}
export default NewsLanding
