import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Dropdown } from 'react-bootstrap';
import axios from "axios"
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import $ from "jquery"

import BuyerForm from "../../forms/buyer-finder-main-form"
import BuyerFinder from "../../forms/buyer-finder-cta-submit"
import ScrollAnimation from 'react-animate-on-scroll';
// import PieChart from "../../../images/development-details/pie-chart.svg";

import {
  isTablet,
  isIPad13,
  isIOS13,
  isMobile,
  isDesktop,
} from "react-device-detect";
import "./ChartArea.scss"
// markup

const COLORS = ['#4d676f', '#93cbdc', '#7394c6', '#e9c873', '#93aa61'];


const ChartArea = (props) => {

  const [postcode, setPostcode] = useState('');
  const [buyerresult, setBuyerResult] = useState('');
  const [loading, setloading] = useState(true);
  const [data, setData] = useState([]);


  useEffect(async () => {
    var target = $("body");
    $('html,body').animate({
      scrollTop: target.offset().top
    }, 100);
    if (localStorage.getItem('buyer-finder-postcode')) {
      setPostcode(localStorage.getItem('buyer-finder-postcode').replace(/\s/g, ''))
      if (localStorage.getItem('buyer-finder-postcode').replace(/\s/g, '')) {

        const home = await window.fetch(`https://wardsofkent-strapi-stage.q.starberry.com/buyerfinder`, {
          method: `POST`,
          body: JSON.stringify({ postcode: localStorage.getItem('buyer-finder-postcode').replace(/\s/g, '') }),

        })

        setBuyerResult(await home.json());

        localStorage.removeItem('buyer-finder-postcode')


        setData([
          { name: 'First time buyer' },
          { name: 'Under offer' },
          { name: 'Cash buyer' },
          { name: 'Own to sell' },
          { name: 'Nothing to sell' },
        ])


        setloading(false)
      }
      if (buyerresult?.buyers?.length > 0) {
        $("#no-result-hide").remove();
      }
      setTimeout(function () { $("#no-result-hide").remove() }, 3000);

    }
  }, []);


  if (loading) return (
    <div className="piechart-loading">
      {postcode ?
        <p>Please wait</p> :
        <BuyerForm />
      }

    </div>
  )
  return (
    <React.Fragment>
      {buyerresult?.buyers && buyerresult?.buyers?.length > 0 ?
        <div className="chart-area">
          <h1 className="d-none">Buyer finder</h1>
          <Container>
            <Row className="align-items-lg-center">
              <Col lg="6">
                <PieChart width={(isTablet || isMobile) ? 350 : 500} height={(isTablet || isMobile) ? 350 : 500}>
                  <Pie
                    data={[
                      { name: 'First time buyer', value: parseInt(buyerresult.buyers[0]?.first_time_buyers) },
                      { name: 'Under offer', value: parseInt(buyerresult.buyers[0]?.under_offer) },
                      { name: 'Cash buyer', value: parseInt(buyerresult.buyers[0]?.cash_buyers) },
                      { name: 'Own to sell', value: parseInt(buyerresult.buyers[0]?.own_to_sell) },
                      { name: 'Nothing to sell', value: parseInt(buyerresult.buyers[0]?.nothing_to_sell) },
                    ]}
                    cx="50%"
                    cy="50%"
                    labelLine={true}
                    // label={renderCustomizedLabel}
                    outerRadius={(isTablet || isMobile) ? 150 : 250}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </Col>
              <Col lg="6">
                <div className="chart-content">
                  <h2>We currently have {buyerresult.total.toLocaleString()} buyers looking for property in {buyerresult.postcode}.
                    {/* We currently have<br/> <span>667 buyers</span> looking for a property in this area. */}
                  </h2>
                  <p>Your property is in a high demand area and Douglas Allen are here to help you on you move forwards. With exclusive access to active buyers in your area, we can find the best buyer for you.</p>

                  <p>With the market seeing the unprecedented levels of activity that it is right now, there’s never been a better time to sell your home!</p>
                  <ul className="chart-point">
                    <li>
                      <i className=""></i> <span>{buyerresult.buyers[0]?.first_time_buyers}</span> First time buyer
                    </li>
                    <li>
                      <i className=""></i> <span>{buyerresult.buyers[0]?.under_offer}</span> Under offer
                    </li>
                    <li>
                      <i className=""></i> <span>{buyerresult.buyers[0]?.cash_buyers}</span> Cash buyer
                    </li>
                    <li>
                      <i className=""></i> <span>{buyerresult.buyers[0]?.own_to_sell}</span> Own to sell
                    </li>
                    <li>
                      <i className=""></i> <span>{buyerresult.buyers[0]?.nothing_to_sell}</span> Nothing to sell
                    </li>
                  </ul>

                  <p className="highlight-content">
                    <strong>Are you interested in finding out the value of your home?</strong> Choose one of the options below to get started.
                  </p>

                  <div className="btn-wrap">
                    <Link to="/sell-your-property/property-valuation" className="btn btn-secondary">
                      <span>Quick and approximate</span>
                      Instant Valuation
                    </Link>
                    <BuyerFinder name={localStorage.getItem('buyer_name')} email={localStorage.getItem('buyer_email')} home_stno={localStorage.getItem('buyer_home_stno')} home_street={localStorage.getItem('buyer_home_street')} home_district={localStorage.getItem('buyer_home_district')} home_post_town={localStorage.getItem('buyer_home_post_town')} home_county={localStorage.getItem('buyer_home_county')} home_postcode={localStorage.getItem('buyer_home_postcode')} id="5" />
                    {/* <Link to="/sell-your-property/property-valuation" className="btn">
                      <span>In-person and accurate</span>
                      Home Visit Valuation
                    </Link> */}
                  </div>
                  <div className="cta-buyer-finder-thanks alert alert-success" id="cta-buyer-finder-thanks">
                    Thank you for your interest. A member of our team will contact you shortly.
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div> :

        <div className="piechart-loading">
          <h2>Sorry, no buyers looking for property in <span>{postcode}</span>.</h2>
          <Container>
            <Row>
              <Col md={12}>
                <div className="buyer-finder-form">
                  {/* <div className="head">
                    <h2>How many active buyers are looking for their new home near you right now?</h2>
                    <p>To get started, simply enter your postcode below.</p>
                  </div> */}
                  <BuyerForm />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      }
    </React.Fragment>
  )
}
export default ChartArea