import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import parse from 'html-react-parser';
import AdvisorImg from "../../images/area-guide/advisors.jpg";
import "../AreaGuideDetails/Advisors/Advisors.scss"
import { NewsDate } from "../common/site/functions"
import avatar from "../../images/avatar.png"
// markup
const NewsHead = (props) => {


  return (
    <React.Fragment>
      {/* <ScrollAnimation animateIn="fadeInUp" animateOnce> */}
        <div className="advisors-block components--AreaGuideDetails--Advisors--Advisors">
          <Container>
            <Row>
              <Col className="d-lg-flex">
                <div className="advisors-text">
                  <p><b>{NewsDate(props.Date)}</b></p>
                  <h1>{props.Title}</h1>
                  {props.Address && <>{props.Address}</>}
                </div>
                {props.People ?
                  <div className="advisor-info">
                    <div className="advisor-img">
                      <img src={props.People.Image.url} alt="" />
                    </div>
                    <div className="advisor-contact">
                      <span className="advisor-name">{props.People.Name}</span>
                      <span className="advisor-designation mb-2">{props.People.Designation}</span>
                      {props.People?.Phone &&
                        <a href={`tel:${props.People.Phone}`} className="phone-number">
                          <i className="icon-phone"></i>
                          <span>{props.People.Phone}</span>
                        </a>
                      }
                    </div>
                  </div> :
                  <div className="advisor-info">
                    <div className="advisor-img">
                      <img src={avatar} alt={`Author`} />
                    </div>
                    <div className="advisor-contact default">
                      <span className="advisor-name">Speak to us today</span>
                      <a href={`tel:08000932271`} className="phone-number">
                        <i className="icon-phone"></i>
                        <span>0800 093 2271</span>
                      </a>
                    </div>
                  </div>
                }

              </Col>
            </Row>
          </Container>
        </div>
      {/* </ScrollAnimation> */}
    </React.Fragment>
  )
}
export default NewsHead