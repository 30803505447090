import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col, Container, Card } from "react-bootstrap";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import parse from 'html-react-parser';

import NoImage from "../../images/douglas-no-image.png"
import { NewsDate } from "../common/site/functions"

const SIMILAR_NEWS = gql`
query SIMNEWS($URL: String!){
    allNews(where:{URL_ne: $URL},limit:6, sort:"Date:desc"){
        id
        Title
        Date
        URL
        Tile_Image {
          url
          alternativeText
        }
    }
}
`;


function MoreNews(props) {

    const { loading, error, data } = useQuery(SIMILAR_NEWS, {
        variables: { URL: props.slug }
    });

    const settings = {
        dots: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },

            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    if (loading) return (
        <p>loading</p>
    )

    return (
        <React.Fragment>
            <section className="guides-section-list more-similar-news custom-slider">
                <Container>
                    <Row className="head">
                        <Col lg={12}>
                            <h2>May also interest you...</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Slider {...settings} className="guide-tile-slide">
                            {data.allNews && data.allNews.map((item, i) => {
                                return (
                                    <Link to={`../${item.URL}`}>
                                        <div className="guide">
                                            <img src={item.Tile_Image ? item.Tile_Image.url : NoImage} alt={item.Tile_Image ? item.Tile_Image.alternativeText : item.Title} />
                                            <p className="title">{item.Title}</p>
                                            <p className="date">{NewsDate(item.Date)}</p>
                                        </div>
                                    </Link>
                                )
                            })}
                        </Slider>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default MoreNews;
