import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby";
import { Breadcrumb, Container, Row, Col } from "react-bootstrap";
import "./Breadcrumbs.scss"
// markup
const Breadcrumbs = (props) => {
  const { href } = useLocation();
  const [render, setRender] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      allAllMenus {
        edges {
          node {
            URL
            Label
            Template
            Main_Parent {
            Label
            URL
            Alias
            }
            Alias
            Sub_Parent {
            Alias
            Label
            URL
            }
            _id
            Secondary_URL
            Sub_Parent_Secondary {
            Label
            URL
            }
          }
        }
      }
    }
  `);

  let PageURL = data.allAllMenus.edges.filter(item => item.node._id === props.Choose_Menu[0]?.id).pop().node;

  let URL = PageURL.Secondary_URL ? PageURL.Secondary_URL : PageURL.URL
 
  var parent_link = ''
  var parent_label = ''
  var sub_parent_link = ''
  var sub_parent_label = ''
  var sub_parent_link_1 = ''
  var sub_parent_label_1 = ''
  let siteUrl= process.env.GATSBY_SITE_URL;
  var ldJson = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
  };
  var itemListElement = [
    { "@type": "ListItem", "position": 1, "name": "Home", "item":  `${siteUrl}` }
  ];
  let position = 1
  if (PageURL.Main_Parent != null) {
    parent_link = PageURL.Main_Parent?.URL
    parent_label = PageURL.Main_Parent?.Label
    position = position+1;
    itemListElement.push({ "@type": "ListItem", "position": position, "name": parent_label, "item":  `${siteUrl}/${parent_link}` }) 
  }
  if (PageURL.Sub_Parent != null) {
    sub_parent_link = PageURL.Sub_Parent?.URL
    sub_parent_label = PageURL.Sub_Parent?.Label
    position = position+1;
    itemListElement.push({ "@type": "ListItem", "position": position, "name": sub_parent_label, "item":  `${siteUrl}/${parent_link}/${sub_parent_link}` }) 
  }
  if (PageURL.Sub_Parent_Secondary != null) {
    sub_parent_link_1 = PageURL.Sub_Parent_Secondary?.URL
    sub_parent_label_1 = PageURL.Sub_Parent_Secondary?.Label
    position = position+1;
    itemListElement.push({ "@type": "ListItem", "position": position, "name": sub_parent_label_1, "item":  `${siteUrl}/${parent_link}/${sub_parent_link}/${sub_parent_link_1}` }) 
  }
  if(props.Pagename){
    itemListElement.push({ "@type": "ListItem", "position": position+1, "name": props.Pagename, "item":  href }) 
  }
  ldJson['itemListElement'] =  itemListElement; 
  useEffect(()=>{
    if(!render){
      setRender(true);
    }
  },[])

  return (
    <React.Fragment>
      <Helmet>
          {render &&
            <script type="application/ld+json">
            {JSON.stringify(ldJson, null, 2)}
          </script>
          } 
      </Helmet>
      {parent_label &&
        <div className="breadcrumb-wrap">
          <Container>
            <Row>
              <Col md='12'>
                <Breadcrumb>
                  <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                  {parent_label &&
                    <Breadcrumb.Item><Link to={`/${parent_link}`}>{parent_label}</Link></Breadcrumb.Item>
                  }
                  {sub_parent_label && parent_label &&
                    <Breadcrumb.Item><Link to={`/${parent_link}/${sub_parent_link}`}>{sub_parent_label}</Link></Breadcrumb.Item>
                  }
                  {sub_parent_label && parent_label && sub_parent_label_1 &&
                    <Breadcrumb.Item><Link to={`/${parent_link}/${sub_parent_link}/${sub_parent_link_1}`}>{sub_parent_label_1}</Link></Breadcrumb.Item>
                  }
                  {/* <Breadcrumb.Item href="#">Areas we cover</Breadcrumb.Item> */}
                  <Breadcrumb.Item active>{props.Pagename}</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>
      }
    </React.Fragment>
  )
}
export default Breadcrumbs